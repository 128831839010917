import { Routes } from '@angular/router';
import { authenticatedGuard, alreadyAuthenticatedGuard } from './core/guards/auth/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./core/auth/auth.component').then(c => c.AuthComponent),
    canActivate: [alreadyAuthenticatedGuard]
  },
  {
    path: 'dispatcher',
    loadChildren: () =>
      import('./features/dispatcher/dispatcher.routes').then(
        (m) => m.dispatcherRoutes
      ),
    canActivate: [authenticatedGuard]
  }
];