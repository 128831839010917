import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const userService = inject(UserService);
  const token = authService.getToken();
  if (token) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'x-tt-tenant-identifier': userService.getTenantId()
      },
    });
  }
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 403) {
        const authData = authService.getAuthData();
        if (!authData) return throwError(() => error);
        return authService.refreshAuthentication(req, next, {
          accessToken: authData.accessToken,
          refreshToken: authData.refreshToken,
        });
      } else if (error.status === 401) {
        authService.logout();
        return throwError(() => error);
      } else {
        return throwError(() => error);
      }
    })
  );
};
