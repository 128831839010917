import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../../services/user/user.service';

export const authenticatedGuard: CanActivateFn = (route, state) => {
  const user = inject(UserService).user;
  const router = inject(Router);
  if (user()) {
    return true;
  }
  router.navigate(['/']);
  return false;
};

export const alreadyAuthenticatedGuard: CanActivateFn = (route, state) => {
  const user = inject(UserService).user;
  const router = inject(Router);
  if (!user()) {
    return true;
  }
  router.navigate(['/dispatcher/booked-loads']);
  return false;
};