<router-outlet />
<p-toast position="bottom-left" [key]="toasterKey" [baseZIndex]="5000">
  <ng-template let-message pTemplate="headless" let-closeFn="closeFn">
    <article class="bg-white py-3 px-4">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-2">
          @let icon = message.data.error ? 'error.svg' : 'success.svg';
          <img
            [src]="'/assets/' + icon"
            [alt]="message.data.error ? 'error' : 'success'"
          />
          <p>{{ message.data.message }}</p>
        </div>
        <button (click)="closeFn()">
          <img src="/assets/close.svg" alt="close" />
        </button>
      </div>
    </article>
  </ng-template>
</p-toast>
